import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route, useLocation} from "react-router-dom";
import Welcome from "./components/Welcome";
import "./style.css"
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import CV from "./components/CV";
import Services from "./components/Services";
import Analytics from "./components/Analytics";
import Privacy from "./components/Privacy";

const FullApp = () => {
    return (
        <BrowserRouter>
            <Analytics/>
            <Routes>
                <Route path="/" element={<Welcome />} />
                <Route path="/cv" element={<CV />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/reference" element={<Portfolio />} />
                <Route path="/kontakt" element={<Contact />} />
                <Route path="/sluzby" element={<Services />} />
                <Route path="/app/cvut.menza/privacy" element={<Privacy />} />
                <Route path="*" element={<h1>404</h1>}/>
            </Routes>
        </BrowserRouter>
    )
}

const rootElement = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
    ReactDOM.hydrate(<FullApp/>, rootElement);
} else {
    ReactDOM.render(<FullApp/>, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
