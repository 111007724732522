import React from "react"
import styled from "styled-components"
import {Screenshot} from "./TabletMockup";
import {Project} from "../../consts/projects";

interface Props {
    project: Project
}

const PhoneMockup = (props: Props) => {
    return (
        <Mockup>
            <AppScreen>
                <Screenshot src={props.project.imageUrl}/>
            </AppScreen>
        </Mockup>
    )
}

export default PhoneMockup

const Mockup = styled.div`
  background-image: url("/img/iPhone-12-Mockup.png");
  width: 20em;
  height: 38em;
  background-size: cover;
  position: relative;
      margin: 0 auto;
`

const AppScreen = styled.div`
  height: 88%;
  width: 78%;
  background-color: #181818;
  position: absolute;
  top: 6%;
  left: 11.5%;
  z-index: -1;
  border-radius: 2em;
`
