import React from "react"
import Layout from "../Layout";
import {BigCenter, Center} from "../shared/mix";
import styled from "styled-components";
import WelcomeBanner from "./WelcomeBanner";
import Logos from "./Logos";
import projects from "../../consts/projects";
import Reference from "./Reference";
import CTA from "../CTA";

interface Props {

}

function randomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Welcome = (props: Props) => {
    return (
        <Layout>
            <BigCenter>
                <WelcomeBanner project={projects[randomInt(0, projects.length-1)]}/>
            </BigCenter>
            <BigCenter>
                <Logos/>
                <Reference project={projects[0]}/>
                <Reference project={projects[1]}/>
                <Reference project={projects[3]}/>
            </BigCenter>

            <CTA/>
        </Layout>
    )
}

export default Welcome

