import React from "react"
import styled from "styled-components"
import Layout from "../Layout";
import {Center, PageTitle} from "../shared/mix";
import {Paragraph, SectionTitle} from "../CV";
import CTA from "../CTA";

interface Props {

}

const Pryvacy = (props: Props) => {
    return (
        <Layout>
            <Center>
                <PageTitle>ČVUT MENZA - Privacy Policy</PageTitle>

                <Info>
                    <h1 className="text-center">Privacy Policy</h1>


                    <p><strong>Effective Date: 29-03-2023</strong></p>
                    <h3>Your privacy is important to us</h3>


                    <p>It is Code Artists's policy to respect your privacy regarding any information we may collect
                        while operating our website. This Privacy Policy applies to <a
                            href="https://codeartists.eu/"> codeartists.eu/</a> (hereinafter, "us", "we", or
                        "codeartists.eu/"). We respect your privacy and are committed to protecting personally
                        identifiable information you may provide us through the Website. We have adopted this privacy
                        policy ("Privacy Policy") to explain what information may be collected on our Website, how we
                        use this information, and under what circumstances we may disclose the information to third
                        parties. This Privacy Policy applies only to information we collect through the Website and does
                        not apply to our collection of information from other sources.</p>
                    <p>This Privacy Policy, together with the Terms of service posted on our Website, set forth the
                        general rules and policies governing your use of our Website. Depending on your activities when
                        visiting our Website, you may be required to agree to additional terms of service.</p><h2
                    id="tableofcontents">Contents</h2>
                    <p>Click below to jump to any section of this privacy policy</p>
                    <ol type="1">
                        <li><a href="#Security"><strong>Security</strong></a>
                        </li>
                        <li><a href="#ExternalLinks"><strong>Links To External Sites</strong></a>
                        </li>
                        <li><a href="#Changes"><strong>Privacy Policy Changes</strong></a>
                        </li>
                        <li><a href="#Credit"><strong>Contact Information &amp; Credit</strong></a>
                        </li>

                    </ol>
                    <h2 id="Security">1. Security</h2><p>The security of your Personal Information is important to us,
                    but remember that no method of transmission over the Internet, or method of electronic storage is
                    100% secure. While we strive to use commercially acceptable means to protect your Personal
                    Information, we cannot guarantee its absolute security.</p>

                    <h2 id="ExternalLinks">2. Links To
                    External Sites</h2><p>Our Service may contain links to external sites that are not operated by us.
                    If you click on a third party link, you will be directed to that third party's site. We strongly
                    advise you to review the Privacy Policy and terms of service of every site you visit.</p>
                    <p>We have no control over, and assume no responsibility for the content, privacy policies or
                        practices of any third party sites, products or services.</p>

                    <h2 id="Changes">3. Privacy Policy
                    Changes</h2><p>Although most changes are likely to be minor, Code Artists may change its Privacy
                    Policy from time to time, and in Code Artists's sole discretion. Code Artists encourages visitors to
                    frequently check this page for any changes to its Privacy Policy. Your continued use of this site
                    after any change in this Privacy Policy will constitute your acceptance of such change.</p>

                    <h2 id="Credit">4. Contact
                    Information &amp; Credit</h2><p>This privacy policy was created at <a
                     href="https://privacyterms.io/privacy-policy-generator/"
                    title="Privacy policy generator" target="_blank">privacyterms.io privacy policy generator</a>. If
                    you have any questions about our Privacy Policy, please contact us via <a
                        href="mailto:info@puhony.eu">email</a> or <a href="tel:">phone</a>.</p>


                </Info>
            </Center>
        </Layout>
    )
}

export default Pryvacy

const Icon = styled.i`
  font-size: 3em;
  position: absolute;
  top: 0em;
  left: -1.5em;
  color: #80808078;
`

const Info = styled.div`
  padding-left: 6em;
`
