import React from "react"
import styled from "styled-components"
import {Project} from "../../consts/projects";

interface Props {
    project: Project
}

const TabletMockup = (props: Props) => {
    return (
        <MockupTablet>
            <AppScreenTablet>
                <Screenshot src={props.project.imageUrl} style={{objectPosition: props.project.bgPos}}/>
            </AppScreenTablet>
        </MockupTablet>
    )
}

export default TabletMockup

export const Screenshot = styled.img`
    object-fit: contain;
    object-position: center top;
    height: 100%;
    width: 100%;
`

const MockupTablet = styled.div`
  background-image: url("/img/ipad-01.svg");
  width: 45em;
  height: 34em;
  background-size: cover;
  position: relative;
  pointer-events: none;
`

const AppScreenTablet = styled.div`
  background-color: #474343;
  width:92%;
  height: 91%;
  position: absolute;
  left: 4%;
  top: 5%;
  z-index: -1;
  pointer-events: auto;
  border-radius: 0.7em;
  overflow: hidden;
  
  ${Screenshot} {
    object-fit: cover;
  }
`
