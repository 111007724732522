export enum ProjectType {
  MOBILE,
  TABLET
}


export interface Project {
  name: string
  sub: string
  type: ProjectType
  imageUrl: string
  bgUrl: string
  bgPos?: string
  urls: {
    android?: string,
    web?: string
    webText?: string
    inProgress?: string
  }
}

const projects: Project[] = [
  {
    name: "Canvass",
    sub: "Prodejní platforma poukazů",
    type: ProjectType.TABLET,
    imageUrl: "/img/screens/canvass.png",
    bgUrl: "/img/bgs/odissei-e1YThDHmTA4-unsplash.jpg",
    urls: {
      web: "https://canvass.vote/",
    }
  },
  {
    name: "Závod-IS",
    sub: "Webová aplikace pro skautské závody",
    type: ProjectType.MOBILE,
    imageUrl: "/img/screens/zavody.png",
    bgUrl: "/img/bgs/jamie-street-_94HLr_QXo8-unsplash.jpg",
    urls: {
      web: "https://zavody.cool.puhony.eu/",
    }
  },
  {
    name: "E-voucher",
    sub: "Prodejní platforma poukazů",
    type: ProjectType.TABLET,
    imageUrl: "/img/screens/evoucher.png",
    bgUrl: "/img/bgs/gift.jpg",
    urls: {
      web: "https://e-voucher.cz/",
    }
  },
  {
    name: "Skaut stěžery",
    sub: "Web pro skautské středisko",
    type: ProjectType.TABLET,
    imageUrl: "/img/screens/skaut2.png",
    bgUrl: "/img/bgs/camp.jpg",
    bgPos: "top center",
    urls: {
      //inProgress: "Dokončuje se",
      web: "https://skautstezery.cz/"
    }
  },
  {
    name: "Steller",
    sub: "Cestovatelské příběhy pro dobrodruhy - web (spolupráce NEXTAP)",
    type: ProjectType.TABLET,
    imageUrl: "/img/screens/steller.png",
    bgUrl: "/img/bgs/surf.jpg",
    bgPos: "top center",
    urls: {
      web: "https://steller.co/"
    }
  },
  {
    name: "ČVUT MENZA",
    sub: "Aplikace pro hladovějící studenty. (Vývoj aplikace byl pozastaven.)",
    type: ProjectType.MOBILE,
    imageUrl: "/img/screens/cvut-menza.webp",
    bgUrl: "/img/bgs/fit.jpeg",
    urls: {
      //android: "https://play.google.com/store/apps/details?id=com.puhony.cvut.menza"
    }
  },
  {
    name: "Laser Game HK",
    sub: "Webová prezentace a rezervační systém",
    type: ProjectType.TABLET,
    imageUrl: "/img/screens/lg.png",
    bgUrl: "/img/bgs/lg.jpeg",
    urls: {
      web: "http://lasergame-hk.cz/",
    }
  },
  /*{
    name: "MHD Pardubice",
    sub: "Neoficiální aplikace pro vyhledávání spojů",
    type: ProjectType.MOBILE,
    imageUrl: "/img/screens/pce-mhd.jpg",
    bgUrl: "/img/bgs/pce.jpg",
    bgPos: "top center",
    urls: {
      inProgress: "Dokončuje se"
    }
  },*/
  {
    name: "Locate.io",
    sub: "Systém pro pasivní lokalizaci wifi zařízení v reálném čase!",
    type: ProjectType.TABLET,
    imageUrl: "/img/screens/locateio.png",
    bgUrl: "/img/bgs/samsung-gc2646c102_640.jpg",
    urls: {
      web: "https://b2017puhoma.delta-www.cz/maturita/dokumentace/puhony_SOC_final.pdf",
      webText: "Dokumentace"
    }
  },
  {
    name: "Tanix",
    sub: "Správa IoT zařízení (ve spolupráci s DAMIDEV)",
    type: ProjectType.TABLET,
    imageUrl: "/img/screens/tanix.png",
    bgUrl: "/img/bgs/warehouse.jpg",
    urls: {
      web: "https://tanix.cz",
      webText: "Web"
    }
  },
  {
    name: "Crowning arts",
    sub: "Kumunitní sociální síť pro sdílení umění. (ve spolupráci s DAMIDEV)",
    type: ProjectType.TABLET,
    imageUrl: "/img/screens/ca.png",
    bgUrl: "/img/bgs/gallery.jpg",
    urls: {
      web: "https://www.crowningarts.com/art/all",
      webText: "Web"
    }
  },
  {
    name: "BurzaŠkol.Online",
    sub: "online náhrada za výstavy škol",
    type: ProjectType.TABLET,
    imageUrl: "/img/screens/burza.png",
    bgUrl: "/img/bgs/delta.webp",
    urls: {
      web: "https://burzaskol.online/clanek/9",
    }
  },
  {
    name: "Zápis do první třídy",
    sub: "online zápisy pro Ústí nad Orlicí",
    type: ProjectType.TABLET,
    imageUrl: "/img/screens/zapisdoprvnitridy.png",
    bgUrl: "/img/bgs/school.jpg",
    urls: {
      web: "https://zapisdoprvnitridy.cz",
    }
  },
  /*{
    name: "Video Chart",
    sub: "Přesná synchronizace videa pro společné sledování",
    type: ProjectType.TABLET,
    imageUrl: "/img/screens/videochart.png",
    bgUrl: "/img/bgs/audience-gfa3fd2410_640.jpg",
    urls: {
      web: "https://video-chart.puhony.eu/",
      webText: "Web aplikace"
    }
  },
   */
]

export default projects
