import React, {useEffect, useRef} from "react"
import styled from "styled-components"
import {useLocation} from "react-router-dom";
import ReactGA from 'react-ga';

interface Props {

}

const Analytics = (props: Props) => {
    const userId = useRef(Math.round(Math.random()*100000000000).toString()+"-"+(new Date()).getTime())
    let location = useLocation();

    useEffect(() => {
        ReactGA.initialize('G-P1NLJ4ZVN8', {
            gaOptions: {
                userId: userId.current
            }
        });
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location])


    return null
}

export default Analytics
