import React from "react"
import styled from "styled-components"
import {Project, ProjectType} from "../../consts/projects";
import TabletMockup from "../Mockups/TabletMockup";
import PhoneMockup from "../Mockups/PhoneMockup";
import ReactGA from 'react-ga';

interface Props {
    project: Project
}

const Reference = (props: Props) => {
    const handleClick = () => {
        ReactGA.event({
            category: 'Reference-Click',
            action: props.project.name
        });
    }

    return (
        <ReferenceWrapper>
            <View>
                <ImageWrapper>
                    <Image style={{backgroundImage: `url(${props.project.bgUrl})`}}/>
                </ImageWrapper>
                <MockupWrapper>
                    {props.project.type === ProjectType.TABLET ? (
                        <TabletMockup project={props.project}/>
                    ) : (
                        <PhoneMockup project={props.project}/>
                    )}
                </MockupWrapper>
            </View>
            <Info>
                <Name>{props.project.name}</Name>
                <Desc>{props.project.sub}</Desc>

                {
                    props.project.urls.android && (
                        <a href={props.project.urls.android} target="_blank" onClick={handleClick}><GoogleBadge/></a>
                    )
                }
                {
                    props.project.urls.web && (
                        <a href={props.project.urls.web}
                           target="_blank"
                           onClick={handleClick}><WebBadge>{props.project.urls.webText || "Web"}</WebBadge></a>
                    )
                }
                {
                    props.project.urls.inProgress && (
                        <InProgressBadge>🏗️ {props.project.urls.inProgress}</InProgressBadge>
                    )
                }

            </Info>
        </ReferenceWrapper>
    )
}

export default Reference

const GoogleBadge = styled.div`
  background-image: url("/img/en_badge_web_generic.png");
  width: 12em;
  height: 4em;
  background-size: cover;
  margin: 0.5em -0.7em;
  cursor: pointer;
  transition: 0.15s;
  display: inline-block;
  
  &:hover {
    transform: scale(1.02);
  }
`

const InProgressBadge = styled.div`
  background-color: #c1c1c1;
  width: 12em;
  height: 3em;
  color: black;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.35em;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin-top: 1.2em;
`

const WebBadge = styled.div`
  background-color: black;
  cursor: pointer;
  transition: 0.15s;
  width: 10.5em;
  height: 3em;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.35em;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin-top: 1.2em;
  
  &:hover {
    transform: scale(1.02);
  }
`

const ReferenceWrapper = styled.div`
  width: 100%;
  margin-bottom: 5em;
  
  @media (max-width: 979px) {
    margin-bottom: 8em;
  }
  
  @media (min-width: 980px) {
      height: 30em;
      display: flex;
      justify-content: space-between;
  }
`

const View = styled.div`
  height: 100%;
  max-width: 40em;
  width: 100%;
  position: relative;
  height: 30em;
  margin-right: 2em;
  
  @media (max-width: 979px) {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
  
  @media (max-width: 450px) {
    height: 23em;
  }
`

const ImageWrapper = styled.div`
  height: 20em;
  width: 100%;
  background-color: #2f3a4b;
    position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
`

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  filter: grayscale(100%) opacity(0.9);
`

const Info = styled.div`
  max-width: 30em;
  width: 100%;
  
  a {
  text-decoration: none;
  color: inherit;
  }
  
  @media (max-width: 979px) {
    margin-top: 3em;
  }
  
  @media (min-width: 980px) {
    height: 30em;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`

const Name = styled.div`
  font-size: 2em;
  font-weight: bold;
`

const Desc = styled.div`

`

const MockupWrapper = styled.div`
  font-size: 0.7em;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  
  @media (max-width: 1140px) {
    font-size: 0.6em;
  }
  
  @media (max-width: 1030px) {
    font-size: 0.5em;
    bottom: 8em;
  }
  
  @media (max-width: 980px) {
    font-size: 0.7em;
    bottom: 0;
  }
  
  @media (max-width: 600px) {
    font-size: 0.6em;
  }
  
  @media (max-width: 530px) {
    font-size: 0.5em;
    bottom: 8em;
  }
  
  @media (max-width: 450px) {
    font-size: 0.4em;
    bottom: 0em;
  }
`
